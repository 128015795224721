<template>
  <div class="SearchMainComponent">
    <h6>SearchMainComponent</h6>
  </div>
</template>

<script>
export default {
  name: "SearchMainComponent",
};
</script>

<style></style>

<template>
<div class="AdminHomeView">
    Admin Home view
</div>

  
</template>

<script>
export default {
name:'AdminHomeView'
}
</script>

<style>
.AdminHomeView{
    margin-top: 70px;
}
</style>
<template>
  <div class="NoProductSelectedComponent">
    Acciones.
  </div>
</template>

<script>
export default {
name:'NoProductSelectedComponent'
}
</script>

<style>

</style>